.home {
    width: 100%;
    min-height: 440px;
    background: var(--container-color);
    display: grid;
    justify-content: center;
    align-items: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='70' height='70' fill='none' stroke='rgb(148 163 184 / 0.10)'%3E%3Cpath stroke-width='0.5' d='M0 .5H31.5V32' fill='none'/%3E%3C/svg%3E");
	background-position: 50% 0%;
	box-sizing: border-box;
	padding: 112px 0px;
}

.home-text {
    color: var(--bg-color);
    text-align: center;
}

.home-image {
    width: 250px; /* Adjust size as needed */
    height: auto;
}

.home-subtitle {
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
}


