.post-box {
    background: var(--bg-color);
    box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
    padding: 15px;
    border-radius: 0.5rem;
    margin-bottom: 20px;
    text-align: left;
}

.image-container {
    position: relative;
}

.post-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 0.5rem;
}

.sold-out-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(211, 211, 211, 0.7);
    /* Light grey background */
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 0.5rem;
    text-transform: uppercase;
}

.post-details {
    margin-top: 15px;
}

.post-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.buy-now-button,
.view-images-button {
    padding: 7px 20px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--bg-color);
    background: #000;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-right: 15px;
    margin-top: 5px;
}

.buy-now-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.buy-now-button:hover:not(:disabled),
.view-images-button:hover {
    background: #24d521;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    max-width: 90%;
    max-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.modal-image {
    max-width: 90%;
    max-height: 80vh;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 2rem;
    color: #000;
    cursor: pointer;
}

.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    padding: 0 20px;
    outline: none;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}

.prev-button:hover,
.next-button:hover {
    color: #24d521;
}
.post-details {
    margin-bottom: 16px;
}

