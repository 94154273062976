.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    height: 70vh;
    overflow: hidden;
    box-sizing: border-box;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.modal-image {
    width: 60%;
    height: 60vh;
}

.prev-button, .next-button {
    background: transparent;
    border: none;
    font-size: 2em;
    cursor: pointer;
    margin: 0 20px;
    color: #24d521;

}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
    .modal {
        padding: 35px 15px;
        position: relative;
        width: 90%;
    }

    .modal-content {
        flex-direction: column;
    }

    .modal-image {
        width: 60%;
    }

    .prev-button, .next-button {
        margin: 10px 0;
        font-size: 1.5em;
    }
}
