header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
}

header.shadow {
    background: var(--bg-color);
    box-shadow: 0 1px 4px hsl(0 4% 14% / 10%);
    transition: 0.4s;
}

header.shadow .logo {
    color: var(--text-color);
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0;
}

.logo {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--bg-color);
}

.logo span {
    color: var(--second-color);
}
