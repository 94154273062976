/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    scroll-padding-top: 2rem;
    box-sizing: border-box;
}

/* Variables */
:root {
    --container-color: #040404;
    --second-color: #39ff14;
    --text-color: #2e402e;
    --bg-color: #fff;
}

::selection {
    color: var(--bg-color);
    background: var(--second-color);
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

img {
    width: 100%;
}

section {
    padding: 3rem 0 2rem;
}

.container {
    max-width: 1068px;
    margin: auto;
    width: 100%;
}
