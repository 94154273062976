.footer {
  background-color: #000;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='70' height='70' fill='none' stroke='rgb(148 163 184 / 0.10)'%3E%3Cpath stroke-width='0.5' d='M0 .5H31.5V32' fill='none'/%3E%3C/svg%3E");
	background-position: 50% 0%;
	box-sizing: border-box;
	padding: 70px 0px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  width: 100px;
  margin-bottom: 10px;
}

.footer-email {
  margin: 10px 0;
  font-size: 1em;
  color: #fff;
}

.footer-catchy-line {
  margin: 10px 0;
  font-size: 1.2em;
}

.footer-text {
  margin: 10px 0;
}
