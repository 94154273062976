.post-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1.5rem;
    margin-top: 2rem !important;
}

.filter-item {
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
}

.active-filter {
    background: var(--second-color);
    color: var(--bg-color);
    padding: 4px 10px;
    border-radius: 4px;
}
